import React from "react";
import PortfolioElement from "./PortfolioElement";
import { PortfolioSection,
  PortfolioText,
  PortfolioImages
} from "../styles/PortfolioSection";

import FourElements from "../assets/FourElements.png";
import Imzeichen from '../assets/imzeichen.jpg';
import WirEn from '../assets/WirEn.png';
import Impuls from '../assets/impuls.jpg';
import Dimension from '../assets/dimension.jpg';
import Klima from '../assets/Klima.png';
import Fee1 from '../assets/fee1.jpg';
import Lauschen1 from '../assets/lauschen1.jpg';
import Bear1 from '../assets/bear.jpg';


const Portfolio = () => {
  return (
    <PortfolioSection id="portfolio">
      <PortfolioText>
        Projects
      </PortfolioText>
      <PortfolioImages>
        <PortfolioElement 
          link={"vierelemente"}
          image={FourElements}
          text={"IM KREIS DER VIER ELEMENTE LUFT, WASSER, ERDE, FEUER"}
        />
        <PortfolioElement 
          link={"imzeichen"}
          image={Imzeichen}
          text={"IM ZEICHEN DER VIER ELEMENTE"}
        />
        <PortfolioElement 
          link={"wiren"}
          image={WirEn}
          text={"WIR EN"}
        />
        <PortfolioElement 
          link={"impuls"}
          image={Impuls}
          text={"I ́MPULS"}
        />
        <PortfolioElement 
          link={"dimension"}
          image={Dimension}
          text={"DIMENSION"}
        />
        <PortfolioElement 
          link={"klimaschutz"}
          image={Klima}
          text={"BILDENDE KÜNSTLER BEZIEHEN STELLUNG ZU KLIMASCHUTZ UND ERNEUERBAREN ENERGIEN"}
        />
        <PortfolioElement 
          link={"feenbeauftragt"}
          image={Fee1}
          text={"FEENBEAUFTRAGT"}
        />
        <PortfolioElement 
          link={"nachinnenlauschen"}
          image={Lauschen1}
          text={"NACH INNEN LAUSCHEN"}
        />
        <PortfolioElement 
          link={"einwortgedichte"}
          image={Bear1}
          text={"EXPERTIMENTELLE LYRIK / EINWORTGEDICHTE"}
        />
      </PortfolioImages>
    </PortfolioSection>
  )
}

export default Portfolio;