import styled from "styled-components";

const CopyrightSection = styled.div`
  height: 150px;
  background-color: rgb(116, 81, 220);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 300px;
  //padding: 0px 20px;

  @media (min-width: 370px) {
    padding-top:0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 60px;
  }
  @media (min-width: 600px) {
    padding-top:0;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 80px;
  }
`

const CopyrightDisclosure = styled.a`
/*
  display: block;
  text-overflow: ellipsis;
  //font-family: Univers;
  font-size: 13px;
  //line-height: 15px;
  //letter-spacing: 0.05em;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  color: rgb(24, 24, 27);
  */
  cursor: pointer;
`

const CopyRightLogo = styled.div`
  img {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
`

const CopyrightIcons = styled.div`
  a {
    margin-right: 20px;
  }
`

export { CopyrightSection,
  CopyrightDisclosure,
  CopyRightLogo,
  CopyrightIcons
 };