import React from "react";
import { useTheme } from "./ThemeContext";
import { NavLink, useNavigate } from 'react-router-dom';
import { AboutSection, 
  AboutContainer, 
  AboutLeftColumn, 
  OurMissionText, 
  AboutBigText, 
  AboutBigTextUnderline,
  AboutRightColumn } from "../styles/AboutSection";
import { VlogFigure } from '../styles/VlogStyles'
import Baum from '../assets/baumlogo.png';
import LinkIcon from '../assets/external-link-icon.svg';



const About = () => {
  const liquidTheme = useTheme()
  const navigate = useNavigate()
  const handleClick = (blogID) => {
    navigate(`/vlog/${blogID}`)
  }
  return (
    <AboutSection id="about">
      <AboutContainer>
        <AboutLeftColumn>
          <OurMissionText>
            About the artist
          </OurMissionText>
          <AboutBigText>
            {liquidTheme 
              ? <div>
                  Bildende Künstlerin und Autorin.
                </div>
              : <div>
                  Bildende Künstlerin und Autorin.
                </div>
            }
          </AboutBigText>
          <OurMissionText>
            Zu meiner Person
          </OurMissionText>
          <div>
            <AboutBigText>
              Ich finde weil ich suche. Das innere Gleichgewicht zwischen mir als Mensch und dem
              Kreislauf der Natur muss immer wieder neu erarbeitet werden. Meine Kunstprojekte spiegeln
              mein Ringen um eigene Ausdrucksformen.
            </AboutBigText>
            <AboutBigTextUnderline onClick={() => handleClick('vita')}>
              Erfahre mehr.
            </AboutBigTextUnderline>
            
          </div>
          
          <OurMissionText>
            Press
          </OurMissionText>
          <AboutBigText>
            <a href="https://drive.google.com/file/d/1-buaGfFm3RSHGbS1tX1HPpegZJRZvWuE/view?usp=sharing" target="_blank">
            Kunstausstellung AUSbruch EINsicht März 2023, Freising Altes Gefängnis
            Eröffnungsrede von
            Helma Dietz, Zweite Vorsitzende des Kunstvereins Modern Studio in Freising
            </a>
          </AboutBigText>
          <AboutBigText>
            <a href="http://www.fink-magazin.de/alle-fink-ausgaben/" target="_blank">
              fink-magazin, Dezember 2021
              Hetti Schubert-Schwall
            </a>
          </AboutBigText>

        </AboutLeftColumn>
          

        <AboutRightColumn>
          <VlogFigure>
            <img src={Baum} alt="Blabla" />
          </VlogFigure>
        </AboutRightColumn>
        
      </AboutContainer>
    </AboutSection>
  )
};

/*


A thesis-driven venture firm 
that builds alongside our founders.

Blockchain technology enables 
permissionless innovation that 
fundamentally reshapes consumer and 
enterprise web business models.

We partner with founders and 
teams to build token-based networks 
and develop the requisite cryptoeconomics, 
governance, and community to scale.
*/

export default About;