import React from "react";
import { BlogSection, 
  BlogSectionText,
  BlogSectionArticleContainer
 } from "../styles/BlogSection";
 import BlogSectionArticle from "./BlogSectionArticle";


const Blog = () => {
  return (
    <BlogSection id="blog">
      <BlogSectionText>
        Latest Exhibitions
      </BlogSectionText>
      <BlogSectionArticleContainer>
        <BlogSectionArticle
        date={"März 2023"}
        title={"AUSbruch EINsicht 23.03. – 03.04.2023 Freising"}
        url={"ausbrucheinsicht"}
        />
        <BlogSectionArticle
        date={"ongoing"}
        title={"WIR EN, ZEICHEN/ZEICHNUNG 2022 - 2023"}
        url={"wirenexhibition"}
        />
        
      </BlogSectionArticleContainer>
    </BlogSection>
  )
}

export default Blog;