import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { VlogContainer, 
    VlogTitle, 
    VlogDateAuthor,
    VlogContent,
    VlogFigureGrid,
    VlogFigure,
    VlogParagraph,
    VlogParagraphLink,
    VlogHeadline3,
    VlogHeadline2,
    VlogOl,
    VlogUl,
    VlogBottom,
    VlogDisclosures
  } from '../styles/VlogStyles';

// images
import headerimg from '../assets/Dalle2Cropped.png';

// Vier Elemente
import Erde from '../assets/Erde-h.jpg';
import Feuer from '../assets/Feuer-h.jpg';
import Wasser from '../assets/Wasser-h.jpg';
import Luft from '../assets/Luft-h.jpg';

// Im Zeichen
import Imzeichen from '../assets/imzeichen.jpg';
import feuer3 from '../assets/Feuer3.png';

// Wir En
import WirEn from '../assets/WirEn.png';

// Impuls
import Impuls from '../assets/impuls.jpg';

// Dimension
import Dimension from '../assets/dimension.jpg';
import Dimension1 from '../assets/dimension1.png';

// Klima
import Klima from '../assets/Klima.png';

// Fee
import Fee1 from '../assets/fee1.jpg';

// Nach Innen Lauschen
import Lauschen1 from '../assets/lauschen1.jpg';
import Lauschen2 from '../assets/lauschen2.jpg';

// Einwortgedichte
import Bear1 from '../assets/bear.jpg';
import Wort1 from '../assets/wort1.jpg';

// Vita
import About from '../assets/about.jpg';

// Wir En Exhibition
import wirenexhibition1 from '../assets/wirenexhibition1.png';
import wirenexhibition2 from '../assets/wirenexhibition2.png';
import wirenexhibition3 from '../assets/wirenexhibition3.png';
import wirenexhibition4 from '../assets/wirenexhibition4.png';

// Ausbruch EInsicht
import ausbruch1 from '../assets/ausbruch-einsicht-header.png';
import ausbruch2 from '../assets/ausbruch2.jpg';
import ausbruch3 from '../assets/ausbruch3.jpg';
import ausbruch4 from '../assets/ausbruch4.jpg';
import ausbruch5 from '../assets/ausbruch5.jpg';
import ausbruch6 from '../assets/ausbruch6.jpg';
import ausbruch7 from '../assets/ausbruch7.jpg';
import ausbruch8 from '../assets/ausbruch8.jpg';
import ausbruch9 from '../assets/ausbruch9.jpg';
import ausbruch10 from '../assets/ausbruch10.jpg';
import ausbruch11 from '../assets/ausbruch11.jpg';
import ausbruch12 from '../assets/ausbruch12.jpg';
import ausbruch13 from '../assets/ausbruch13.jpg';
import ausbruch14 from '../assets/ausbruch14.jpg';
import ausbruch15 from '../assets/ausbruch15.jpg';
import ausbruch16 from '../assets/ausbruch16.jpg';
import ausbruch17 from '../assets/ausbruch17.jpg';
import ausbruch18 from '../assets/ausbruch18.jpg';


const Vlog = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { id } = useParams()
  const navigate = useNavigate()
  const handleClick = (id) => {
    navigate(`/vlog/${id}`)
  }

  if (id === "vierelemente") {
    return (
      <VlogContainer>
        <VlogTitle>
          IM KREIS DER VIER ELEMENTE LUFT, WASSER, ERDE, FEUER
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogFigureGrid>
              <VlogFigure>
                <img src={Erde} alt="Blabla" />
              </VlogFigure>
              <VlogFigure>
                <img src={Feuer} alt="Blabla" />
              </VlogFigure>
              <VlogFigure>
                <img src={Wasser} alt="Blabla" />
              </VlogFigure>
              <VlogFigure>
                <img src={Luft} alt="Blabla" />
              </VlogFigure>
            </VlogFigureGrid>
            
            <VlogParagraph>
              Vier Bilder auf Großbannern, 1m x 3,50m oder im Original, 26 cm x 1 m, stellen 
              Variationen der Elemente Feuer, Wasser, Luft und Erde dar. Zusammen bilden sie 
              eine Komposition des Kreislaufs der vier Elemente.
            </VlogParagraph>
            
            <VlogParagraph>
              Die Elemente sind in freier Wahl den vier Himmelsrichtungen zugeordnet, in 
              denen die Bilder, wenn möglich, aufgehängt werden sollen.
            </VlogParagraph>
            <VlogParagraph>
              Der Betrachter ist aufgefordert, die Mitte des Kreislaufes zu sein. Dreht er/sie 
              sich um die eigene Achse, so „ziehen die Elemente an ihm/ihr vorüber“, ein 
              Sinnbild für die Bewegung alles Lebendigen im eigenen Leben wie in der Natur.
            </VlogParagraph>
            
            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  if (id === "imzeichen") {
    return (
      <VlogContainer>
        <VlogTitle>
          IM ZEICHEN DER VIER ELEMENTE
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogFigure>
              <img src={Imzeichen} alt="Blabla" />
            </VlogFigure>
            
            <VlogParagraph>
            Im Zeichen der vier Elemente ist ein vielseitiges Kunstprojekt, das im Wesentlichen 
            die Idee des Gleichgewichts des Menschen mit der Natur aufgreift.
            Das Projekt hilft interessierten Menschen durch künstlerische Ausdruckskraft die 
            Quelle der Kreativität frei zu legen, welche wichtig ist, neue Wege für die Zukunft zu 
            entwickeln
            </VlogParagraph>
            
            <VlogParagraph>
            Es hat seinen Ursprung in der Zusammenarbeit der Künstlerin Hetti Schubert-Schwall mit 
            indianischen Ureinwohnern aus Kolumbien und Nordamerika.
            Heute hat sich Im Zeichen der vier Elemente weiter entwickelt und wird in sozialen 
            Projekten, Kunstveranstaltungen und in der Rehabilitation eingesetzt.
            </VlogParagraph>

            <VlogFigure>
              <img src={feuer3} alt="Blabla" />
            </VlogFigure>
            
            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  if (id === "wiren") {
    return (
      <VlogContainer>
        <VlogTitle>
          WIR EN
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogFigure>
              <img src={WirEn} alt="Blabla" />
            </VlogFigure>
            
            <VlogParagraph>
              An manchen Tagen, in Atempausen vom Alltag, tauchen Fragen nach dem Sinn des eigenen 
              Lebens auf: Wozu das alles? Warum bin ich auf der Welt? Ist das, was ich tue gut, ist 
              es das Richtige? Hat der Ort, an dem ich lebe, eine Bedeutung?
            </VlogParagraph>
            
            <VlogParagraph>
              Wenn ich in einer solchen Pause meinen Atem einmal bewusst wahrnehme, fällt mir auf, 
              dass ich ihn normalerweise gar nicht bemerke. Mein Geist ist auf andere Dinge konzentriert 
              und mein Körper scheint unabhängig nebenbei zu leben. Er sitzt zum Beispiel am Computer, 
              scheint währenddessen unwichtig zu sein. In Abständen hat er Bedürfnisse. Dann nehme ich 
              ihn wahr. Und erst, wenn ich eine Maske tragen muss, die mir das Atmen erschwert, mache ich 
              mir über das Atmen Gedanken.
            </VlogParagraph>

            <VlogParagraphLink>
              <a href="https://drive.google.com/file/d/1fHukv_ckMP3iYKX8jq9YK4GSbMTyRhMG/view?usp=sharing" target="_blank">
              Erfahre mehr
              </a>
            </VlogParagraphLink>
            
            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  if (id === "impuls") {
    return (
      <VlogContainer>
        <VlogTitle>
          I ́MPULS
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogHeadline2>
              INSTALLATION LICHT UND ERDE
            </VlogHeadline2>
            <VlogFigure>
              <img src={Impuls} alt="Blabla" />
            </VlogFigure>
            
            <VlogParagraph>
              Von mehreren Metern hoch aufgehängten Transparentpapierbahnen fallen mit feuchtem Ton 
              aufgezogene, senkrechte Schriftzüge in einem durch den Trocknungsprozess des Tons bedingtem 
              Rhythmus auf große alte Bleche (ehemalige Münchner Dachbleche).
            </VlogParagraph>
            
            <VlogParagraph>
              Der Aufprall, akustisch verstärkt, ist als Donner hörbar. Dies geschieht in unregelmäßigen, 
              zufälligen Abständen. Auf den Transparentpapierbahnen bleiben Spuren zurück, die durch Licht 
              verstärkt, sichtbar werden.
            </VlogParagraph>

            
            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  if (id === "dimension") {
    return (
      <VlogContainer>
        <VlogTitle>
          DIMENSION
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogFigure>
              <img src={Dimension1} alt="Blabla" />
            </VlogFigure>
            
            <VlogParagraph>
              Eine Kerze vor einer Bronzeskulptur wirft einen Schatten auf ein Transparentpapier 
              an der Wand, welches mit schwarzer Tonerde bedeckt ist.
            </VlogParagraph>
            
            <VlogParagraph>
              Mit dem Niederbrennen der Kerze steigt der Schatten der Bronzeskulptur aus dem Bereich 
              der dunklen Tonerde an der hellen Wand auf.
              Kerze und Bronzeskulptur sind auf einem Stahlträger befestigt, der in die Wand geschraubt ist.
            </VlogParagraph>

            <VlogParagraph>
              Das Niederbrennen der Kerze dauert ca. 8 Stunden – der Zeitzyklus eines halben Tages. Die feuchte 
              Tonerde fällt in ihrer eigenen Trockenzeit vom Transparentpapier auf den Boden.
              Die Bronzeskulptur erfährt ihre Veränderung und ihre Bewegung durch ihren Schatten, im Wechselspiel 
              von Licht und Erde.
            </VlogParagraph>

            <VlogParagraph>
              Es gibt sieben Bronzeskulpturen – für jeden Tag der Woche eine andere.
            </VlogParagraph>

            <VlogFigure>
              <img src={Dimension} alt="Blabla" />
            </VlogFigure>
            
            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  if (id === "klimaschutz") {
    return (
      <VlogContainer>
        <VlogTitle>
          BILDENDE KÜNSTLER BEZIEHEN STELLUNG ZU KLIMASCHUTZ UND ERNEUERBAREN ENERGIEN
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogFigure>
              <img src={Klima} alt="Blabla" />
            </VlogFigure>
            
            <VlogParagraph>
              Fünf Künstler der Akademie der Bildenden Künste München zeigten im Jahr 2009 ihren 
              persönlichen Zugang zu dem Thema Klimaschutz und erneuerbare Energien.
            </VlogParagraph>
            
            <VlogParagraph>
              In der Kunstausstellung in München auf der Leopoldstrasse am Siegestor wurde der Besucher 
              eingeladen, die Umwelt durch die Augen der Künstler zu sehen.
            </VlogParagraph>

            <VlogParagraph>
              In spannenden Aktionen konfrontierten sie den Betrachter mit der Urkraft des Feuers (fusion 
              Till Schilling), nahmen die Bewegung der Luft auf (honest Nele Müller), lösten den Besucher 
              auf Wunsch von seinem Image (no-logo-service Timur Dizdar), machten das ungenutzte Potential 
              der Energie der Sonne und des Regens sichtbar. (zurück zur Natur Boris Maximov)
            </VlogParagraph>

            <VlogParagraph>
              Initiatorin des Projektes war die Künstlerin Hetti Schubert-Schwall, die mit zwei eigenen 
              Kunstprojekten, im Zeichen der vier Elemente und sky lines, die EU-Veranstaltung Energy Union 
              it ́s time zu Klimaschutz und erneuerbaren Energien in 12 Städten Europas unterstützte.
            </VlogParagraph>

            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  if (id === "feenbeauftragt") {
    return (
      <VlogContainer>
        <VlogTitle>
          FEENBEAUFTRAGT
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogFigure>
              <img src={Fee1} alt="Blabla" />
            </VlogFigure>
            
            <VlogParagraph>
              In Naturfotografien und lyrischen Texten werden Täuschungen entlarvt, die sich durch gewohnte 
              Wahrnehmungsmuster im menschlichen Alltag eingestellt haben. Umgekehrt wird der Mensch jedoch 
              auch von seinen Sinnen getäuscht.
            </VlogParagraph>
            
            <VlogParagraph>
              Die Wirklichkeit scheint die menschliche Wahrnehmung durch ihre Erscheinungsbilder zu foppen 
              und heraus zu fordern. Die Natur entwickelt in geheimnisvoller Art und Weise für jeden Mensch, 
              jeden Tag aufs Neue eine eigene Sprache, mit der sie den einzelnen persönlich zu erreichen sucht.
              Nur haben es die Menschen oft verlernt, die Sprache der Natur zu verstehen...
            </VlogParagraph>

            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  if (id === "nachinnenlauschen") {
    return (
      <VlogContainer>
        <VlogTitle>
          NACH INNEN LAUSCHEN
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogFigure>
              <img src={Lauschen1} alt="Blabla" />
            </VlogFigure>
            
            <VlogParagraph>
              Menschen werden aufgefordert einzelnen Tönen der C-Dur Tonleiter auf dem Klavier zu lauschen. Sie
              ordnen ihnen die Farbe zu, die sie bei diesem Ton innerlich empfinden. Sie teilen mit, wo sie den 
              Ton mit der Farbe lokalisieren.
            </VlogParagraph>
            <VlogHeadline2>
              Interaktion
            </VlogHeadline2>
            
            <VlogParagraph>
              Sie werden in Lebensgröße auf Leinwand gemalt. Es werden ihnen die Töne der C-Dur Tonleiter einzeln 
              mit allen Halbtönen auf dem Klavier vorgespielt. Nach jedem Ton nimmt sich die Person Zeit und lauscht 
              der Resonanz des Tons.
            </VlogParagraph>

            <VlogParagraph>
              „Hat der Ton eine Farbigkeit? Wo spürst du den Ton? Klingt er innerhalb oder außerhalb des Körpers?“
            </VlogParagraph>

            <VlogParagraph>
              Anhand einer umfangreichen Farbtabelle sucht die Person die Farbe heraus, die ihrem Farbempfinden des 
              Tons annähernd entspricht. Dies dient später als Grundlage für die künstlerische Umsetzung der Tonleiter 
              im Bild.
            </VlogParagraph>

            <VlogParagraph>
              Interessant ist, dass Menschen „Farben sehen können“ die für andere nicht sichtbar sind. Die Farbwahrnehmung 
              entsteht allein durch die Resonanz des Tons im Inneren und ist bei jeder Person einzigartig.
            </VlogParagraph>

            <VlogFigure>
              <img src={Lauschen2} alt="Blabla" />
            </VlogFigure>

            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  if (id === "einwortgedichte") {
    return (
      <VlogContainer>
        <VlogTitle>
          EXPERTIMENTELLE LYRIK / EINWORTGEDICHTE
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogFigure>
              <img src={Bear1} alt="Blabla" />
            </VlogFigure>
            
            <VlogParagraph>
              Ungewöhnliche Wortkombinationen aus drei einzelnen Worten ergeben stimmungsvolle Gesamtworte, welche 
              die Leser dazu anregen, ihrer persönlichen Vorstellungskraft freien Lauf zu lassen. Gedankenassoziationen 
              und innere Bilder werden als bereichsübergreifende Kunst gesehen. Sie vervielfältigen sich in veränderlichen 
              künstlerischen Ausdrucksformen und durch die Anzahl der Leser.
            </VlogParagraph>
            
            <VlogParagraph>
              So wird die „unendliche Galerie der inneren Bilder“ zum Forum für persönliche Vorstellungen, Bilder, Träume 
              und Visionen. Sie ernst zu nehmen und ihnen einen Stellenwert in der eigenen Kultur zu geben ist in dieser 
              Arbeit das besondere Anliegen der Künstlerin.
            </VlogParagraph>

            <VlogFigure>
              <img src={Wort1} alt="Blabla" />
            </VlogFigure>

            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  if (id === "vita") {
    return (
      <VlogContainer>
        <VlogTitle>
          Vita
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogFigure>
              <img src={About} alt="Blabla" />
            </VlogFigure>

            <VlogHeadline2>
              Kurzbiographie
            </VlogHeadline2>

            <VlogParagraph>
              Seit einem vielseitigen Kunststudium an der Akademie der Bildenden Künste
              München von 1979 bis 1983, arbeitet die Künstlerin Hetti Schubert-Schwall als 
              freischaffende Künstlerin und Autorin von künstlerischen Büchern. Sie kreiert 
              Kunstprojekte zu aktuellen Themen, organisiert und nimmt an Kunstausstellungen teil,
              arbeitet in sozialen Projekten wie beispielsweise an Jugendzentren und
              Schulen und ist Mutter von drei Kindern.
            </VlogParagraph>

            <VlogHeadline2>
              Ausbildung
            </VlogHeadline2>
            <VlogUl>
              <li>
                1984 - heute: Freiberufliche Künstlerin und Autorin. Malerei, Zeichnung, 
                bereichsübergreifende künstlerische Arbeitsprojekte, eigene Kunstbücher
              </li>
              <li>
                1983: Staatsexamen
              </li>
              <li>
                1977 - 1983: Studium an der Akademie der Bildenden Künste München, u.a. Prof. Josef Beuys
              </li>
              <li>
                1975 - 1976: Studium der Kunstgeschichte, Philosophie, Archäologie, Ludwig Maximilians Universität, München
              </li>
            </VlogUl>
            <VlogHeadline2>
              Werke
            </VlogHeadline2>

            <VlogHeadline3>
              2023
            </VlogHeadline3>
            <VlogUl>
              <li>
                Gemeinschaftsausstellung AUSbruch EINsicht
              </li>
              <li>
                Frank Fischer, Maria Kies, Nadia Müller, Hetti Schubert-Schwall
              </li>
              <li>
                Altes Gefängnis Freising
              </li>
              <li>
                OFFENES ATELIER Obermenzinger Gymnasium, München
              </li>
            </VlogUl>

            <VlogHeadline3>
              2022
            </VlogHeadline3>
            <VlogUl>
              <li>
                Ausstellung Bad Gastein WIR EN
              </li>
              <li>
                Ausstellung Bad Gastein ZEICHEN UND ZEICHNUNG
              </li>
              <li>
                OFFENES ATELIER Obermenzinger Gymnasium, München
              </li>
            </VlogUl>

            <VlogHeadline3>
              2021
            </VlogHeadline3>
            <VlogUl>
              <li>
                Kunstbuch WIR EN, Neustart Kultur
              </li>
              <li>
                Ausstellung Bad Gastein, IM KREIS DER VIER ELEMENTE
              </li>
              <li>
                Ausstellungsbeteiligung Altes Gefängnis Freising,
              </li>
              <li>
                Jubiläumsausstellung 50 Jahre Kulturverein Modern Studio
              </li>
              <li>
                Lesungen aus FEENBEAUFTRAGT und WIR EN
              </li>
            </VlogUl>

            <VlogHeadline3>
              2020
            </VlogHeadline3>
            <VlogUl>
              <li>
                Ausstellung ZEICHEN UND ZEICHNUNG, Altes Gefängnis Freising
              </li>
              <li>
                mit den Künstlern Manfred Popp und Hetti Schubert-Schwall
              </li>
            </VlogUl>

            <VlogHeadline3>
              2019
            </VlogHeadline3>
            <VlogUl>
              <li>
                Kunstprojekt BAUMBILDUNG
              </li>
              <li>
                Ausstellung online <a href="https://baumbildung.de/">www.baumbildung.de</a>
              </li>
            </VlogUl>

            <VlogHeadline3>
              2018
            </VlogHeadline3>
            <VlogUl>
              <li>
                Kunstprojekt OFFENES ATELIER, Obermenzinger Gymnasium, München
              </li>
              <li>
                ZEICHNUNG, Ausstellung München Neuhausen Kultüren 16
              </li>
            </VlogUl>

            <VlogHeadline3>
              2017
            </VlogHeadline3>
            <VlogUl>
              <li>
                Ausstellung NACH INNEN LAUSCHEN, Christuskirche München
              </li>
              <li>
                Kunstprojekt OFFENES ATELIER, Obermenzinger Gymnasium, München
              </li>
              <li>
                MALEREI/EXPERIMENT: NACH INNEN LAUSCHEN Trafo, München Neuhausen, Kultüren 15
              </li>
            </VlogUl>

            <VlogHeadline3>
              2016
            </VlogHeadline3>
            <VlogUl>
              <li>
                Galerie Zeitlang Kunst: ABBRUCH -AUFBRUCH, München Pasing Marienplatz
              </li>
              <li>
                Ausstellung EINWORTGEDICHTE, Lesung EINWORTGEDICHTE
              </li>
              <li>
                Kunstprojekt OFFENES ATELIER, Obermenzinger Gymnasium, München
              </li>
              <li>
                Ausstellung: ZEICHEN/ZEICHNUNG, Trafo, München Neuhausen, Kultüren 14
              </li>
            </VlogUl>

            <VlogHeadline3>
              2015
            </VlogHeadline3>
            <VlogUl>
              <li>
                Kunstprojekt OFFENES ATELIER, Obermenzinger Gymnasium, München
              </li>
            </VlogUl>

            <VlogHeadline3>
              2014
            </VlogHeadline3>
            <VlogUl>
              <li>
                Kunstprojekt Kinderbuch DER KLEINE WOLF, Lesungen an Schulen
              </li>
              <li>
                Kunstprojekt OFFENES ATELIER, Obermenzinger Gymnasium, München
              </li>
            </VlogUl>

            <VlogHeadline3>
              2013
            </VlogHeadline3>
            <VlogUl>
              <li>
                Ausstellung Kunstprojekt DER KLEINE WOLF
              </li>
              <li>
                Löhehaus, München Neuhausen, Kultüren 11
              </li>
              <li>
                Kunstprojekt DER KLEINE WOLF Lesungen an Schulen
              </li>
              <li>
                Kunstprojekt OFFENES ATELIER Obermenzinger Gymnasium, München
              </li>
            </VlogUl>

            <VlogHeadline3>
              2012
            </VlogHeadline3>
            <VlogUl>
              <li>
                Teilhabe Atelier Stefan Conradi, München Neuhausen
              </li>
              <li>
                Ausstellung DIMENSION, München Neuhausen, Kultüren 10
              </li>
              <li>
                Kunstprojekt OFFENES ATELIER Obermenzinger Gymnasium, München
              </li>
              <li>
                Kunstprojekt Kinderbuch DER KLEINE WOLF FIU-Verlag Wangen, Allgäu
              </li>
              <li>
                Ausstellung Kunstprojekt DER KLEINE WOLF Waldorfschule Wangen, Allgäu
              </li>
            </VlogUl>

            <VlogHeadline3>
              2011
            </VlogHeadline3>
            <VlogUl>
              <li>
                Interaktive Kunstausstellung EINWORTGEDICHTE
              </li>
              <li>
                Buchhandlung MOTHS München
              </li>
            </VlogUl>

            <VlogHeadline3>
              2010
            </VlogHeadline3>
            <VlogUl>
              <li>
                FEENNBEAUFTRAGT Kunst/Literaturprojekt
              </li>
              <li>
                Weiterführung des Kunstprojektes Bildende Künstler beziehen Stellung zu
              </li>
              <li>
                Klimaschutz und erneuerbaren Energien
              </li>
            </VlogUl>

            <VlogHeadline3>
              2009
            </VlogHeadline3>
            <VlogUl>
              <li>
                Bildende Kunst und Klimaschutz
              </li>
              <li>
                interaktives Kunstprojekt mit den Künstlern Timur Dizdar, Boris Maximov, 
                Nele Müller, Till Schilling, Hetti Schubert-Schwall anlässlich des internationalen 
                EU-Projektes ENERGY UNION IT ́`S TIME zu Klimaschutz und erneuerbaren Energien
              </li>
            </VlogUl>

            <VlogHeadline3>
              2008
            </VlogHeadline3>
            <VlogUl>
              <li>
                WORTBILDENDE KUNST und öffentlicher Raum
              </li>
              <li>
                Kunstaktionen, Fotomontagen, Licht und Erde Objekte
              </li>
              <li>
                Ausstellung I ́MPULS Galerie der Künstler München
              </li>
            </VlogUl>

            <VlogHeadline3>
              2007
            </VlogHeadline3>
            <VlogUl>
              <li>
                Buschzeit Verlag, Kunst/Literaturprojekte
              </li>
              <li>
                Einwortgedichte BÄRENVOGELWOLKE
              </li>
            </VlogUl>

            <VlogHeadline3>
              2006
            </VlogHeadline3>
            <VlogUl>
              <li>
                WORT KUNST
              </li>
              <li>
                Einwortgedichte
              </li>
            </VlogUl>

            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

      </VlogContainer>
    )
  }

  if (id === "wirenexhibition") {
    return (
      <VlogContainer>
        <VlogTitle>
          WIR EN, ZEICHEN/ZEICHNUNG 2022 - 2023
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogHeadline2>
              Ort
            </VlogHeadline2>
            <VlogParagraph>
              Österreich, 5640, Bad Gastein, Kaiser-Wilhelm-Promenade 2a
            </VlogParagraph>

            <VlogFigure>
              <img src={wirenexhibition1} alt="Blabla" />
            </VlogFigure>
            <VlogFigure>
              <img src={wirenexhibition2} alt="Blabla" />
            </VlogFigure>
            <VlogFigure>
              <img src={wirenexhibition3} alt="Blabla" />
            </VlogFigure>
            <VlogFigure>
              <img src={wirenexhibition4} alt="Blabla" />
            </VlogFigure>

            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  if (id === "ausbrucheinsicht") {
    return (
      <VlogContainer>
        <VlogTitle>
          AUSbruch EINsicht 23.03.2023 - 03.04.2023
        </VlogTitle>
        <VlogDateAuthor>
          Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            <VlogFigure>
              <img src={ausbruch1} alt="Ausbruch Einsicht Bild" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch2} alt="Ausbruch Einsicht Eröffnung" />
            </VlogFigure>
            <VlogParagraph>
            Um zu neuen Einsichten zu gelangen, ist es oft notwendig aus alten Mustern 
            und Denkweisen auszubrechen. Fünf Bildende Künstler/Innen treffen sich unter 
            dem Thema AUSbruch EINsicht. Sie zeigen ihre eigenen Positionen und ihre 
            Wertschätzung der Natur. wERDschätzung, Im Kreis der Vier Elemente, 
            Auseinandersetzung mit innerer Krankheit und äußerer Welt, Transformation, 
            Symmetrie und Natur ... machen neugierig auf einen Besuch.
            </VlogParagraph>
            <VlogHeadline2>
              Initiatorin Hetti Schubert-Schwall
            </VlogHeadline2>
            <VlogParagraph>
            Kunststudium an der Akademie der Bildenden Künste München von 1979 bis 1983. 
            Seitdem freischaffend tätig.
            In immer neuen Variationen zeigt die Künstlerin Hetti Schubert-Schwall Bilder 
            der vier Elemente Luft, Feuer, Wasser und Erde. Sich möglichst jeden Tag auf 
            die Suche nach einem Gleichgewicht zwischen Mensch und Natur zu begeben, ist 
            ihr ein wertvolles künstlerisches und menschliches Anliegen.
            </VlogParagraph>
            <VlogFigure>
              <img src={ausbruch3} alt="Ausbruch Einsicht Bild 3" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch4} alt="Ausbruch Einsicht Bild 4" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch5} alt="Ausbruch Einsicht Bild 5" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch6} alt="Ausbruch Einsicht Bild 6" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch7} alt="Ausbruch Einsicht Bild 7" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch8} alt="Ausbruch Einsicht Bild 8" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch9} alt="Ausbruch Einsicht Bild 9" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch10} alt="Ausbruch Einsicht Bild 10" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch11} alt="Ausbruch Einsicht Bild 11" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch12} alt="Ausbruch Einsicht Bild 12" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch13} alt="Ausbruch Einsicht Bild 13" />
            </VlogFigure>
            <VlogFigure>
              <img src={ausbruch14} alt="Ausbruch Einsicht Bild 14" />
            </VlogFigure>
            
            <VlogHeadline2>
              Manfred Bauer
            </VlogHeadline2>
            <VlogParagraph>
            Manfred Bauer, geboren 1952 in München. Sein künstlerisches Tun ist geprägt 
            von der Auseinandersetzung mit persönlicher Krankheit und der Krise unserer 
            Gesellschaft – auf der Suche nach lebendigem Ausdruck, lebendiger Form, 
            lebendigem Inhalt. Der Form – und Farbenreichtum der Natur ist die Anregung 
            und die Quelle.
            </VlogParagraph>
            <VlogFigure>
              <img src={ausbruch17} alt="Ausbruch Einsicht Bild 17" />
            </VlogFigure>
            <VlogHeadline2>
            Frank Fischer
            </VlogHeadline2>
            <VlogParagraph>
            Künstler und Wahrnehmungs-Coach, geboren in München, seit 2015 Atelier mit 
            Galerie in Weilheim/Obb.
            Seine gemeinschaftliche Kunst-Aktion wERDschätzung thematisiert die Beziehung 
            zwischen Mensch und Erde. Mit einer Humus-Installation, Malerei und Zeichnungen 
            lädt Frank Fischer ein, auf spielerisch fantasievolle Weise die Erde als unseren 
            Ur-Grund zu begreifen und ihr mit allen Sinnen zu begegnen.
            </VlogParagraph>
            <VlogFigure>
              <img src={ausbruch18} alt="Ausbruch Einsicht Bild 18" />
            </VlogFigure>
            <VlogHeadline2>
            Maria Kiess
            </VlogHeadline2>
            <VlogParagraph>
            Das Leben, das in der Natur und in der menschlichen Person zum Ausdruck kommt, 
            ist der künstlerische Ausgangspunkt für Maria Kiess. Die unerschöpfliche Farben 
            -und Formenvielfalt der Natur ist für sie Hinweis auf das dahinter liegende 
            Wechselspiel geistig- seelischer und religiöser innerer Vorgänge. In ihren 
            Werkformen verbindet sie Zeichnungen, großformatige Cuts und farbige Druckgrafik.
            </VlogParagraph>
            <VlogFigure>
              <img src={ausbruch15} alt="Ausbruch Einsicht Bild 15" />
            </VlogFigure>
            <VlogHeadline2>
            Nadia Müller
            </VlogHeadline2>
            <VlogParagraph>
            Nadia Müller geboren 1990 in München, Künstlerin, Goldschmiedin und Erzieherin. 
            Mittels Scherenschnitt und Zeichnung setzt die Künstlerin sich mit den Themen 
            Symmetrie und der Beziehung zwischen Materie und dem Unsichtbaren auseinander. 
            Ihren Arbeitsprozess beschreibt sie als eine Art Metamorphose: Das Papier bricht 
            aus seiner gewohnten Form aus und immer neue Konstellationen, Formen und Wesen 
            treten aus dem Papier hervor, werden sichtbar.
            </VlogParagraph>
            <VlogFigure>
              <img src={ausbruch16} alt="Ausbruch Einsicht Bild 16" />
            </VlogFigure>

            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  if (id === "disclosures") {
    return (
      <VlogContainer>
        <VlogTitle>
          Disclosures / Impressum / Datenschutz
        </VlogTitle>
        <VlogDateAuthor>
          August 30, 2022 — Hetti Schubert-Schwall
        </VlogDateAuthor>
        <VlogContent>
          <div>
            
            <VlogHeadline3>
              Impressum
            </VlogHeadline3>
            <VlogUl>
              <li>
                Hetti Schubert-Schwall
              </li>
              <li>
                Bildende Künstlerin
              </li>
              <li>
                Frundsbergstraße 60
              </li>
              <li>
                80637 München
              </li>
              <li>
                Tel.: 089 131530
              </li>
              <li>
                E-Mail: hetti @ schubert-schwall . com
              </li>
            </VlogUl>
            
            <VlogParagraph>
              Sofern jemand meine E-Mail-Adresse zur Kontaktaufnahme verwendet, wird die E-Mailadresse dieser 
              Person nicht an Dritte weitergegeben, es sei denn, es liegt ein explizites Einverständnis vor.
            </VlogParagraph>

            <VlogHeadline3>
              Urheberrecht  
            </VlogHeadline3>
            <VlogParagraph>
              Die Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Beiträge Dritter 
              sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der 
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des 
              jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, 
              nicht kommerziellen Gebrauch gestattet.
            </VlogParagraph>
            <VlogParagraph>
              Geschützt durch VG BILD KUNST
            </VlogParagraph>

            <VlogHeadline3>
              Datenschutzerklärung  
            </VlogHeadline3>
            <VlogParagraph>
              Die Nutzung der Angebote ist ohne Angabe personenbezogener Daten möglich. Die Preisgabe 
              personenbezogener Daten erfolgt auf freiwilliger Basis. Der Nutzung von im Rahmen der 
              Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht 
              ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich 
              widersprochen. Im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch 
              Spam-Mails, behalte ich mir rechtliche Schritte vor.
            </VlogParagraph>
            
            <VlogParagraph>
              Ab dem 25. Mai 2018 gilt innerhalb der EU die DSGVO. Obwohl diese Webseite privat ist, 
              werden hier sicherheitshalber einige datenschutzrechtliche Punkte festgestellt:
            </VlogParagraph>

            <VlogOl>
              <li>
                Diese Webseite verwendet kein Google Analytics.
              </li>
              <li>
                Diese Webseite speichert keine personenbezogenen Daten der Besucher.
              </li>
              <li>
                Diese Webseite enthält kein Kontaktformular.
              </li>
            </VlogOl>

            <VlogParagraph>
              Rechtswirksamkeit
            </VlogParagraph>

            <VlogParagraph>
              Dieser Haftungsausschluss ist als Teil meines Internetangebots zu betrachten. Sofern Teile 
              oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht mehr entsprechen 
              sollten, bleiben die übrigen Teile des Dokuments davon unberührt. Gezeichnet Hetti Schubert-Schwall
            </VlogParagraph>


            <VlogBottom/>
        
            <VlogDisclosures onClick={() => handleClick('disclosures')}>
              Disclosures
            </VlogDisclosures>
          </div>
        </VlogContent>

    </VlogContainer>
    )
  }

  return (
    <VlogContainer>
      <VlogTitle>
        Exhibition One Two Three Four
      </VlogTitle>
      <VlogDateAuthor>
        August 30, 2022 — FirstName LastName
      </VlogDateAuthor>
      <VlogContent>
        <div>
          <VlogFigure>
            <img src={headerimg} alt="Blabla" />
          </VlogFigure>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogHeadline2>
            Major Findings
          </VlogHeadline2>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogOl>
            <li>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.
            </li>
            <li>
              At vero eos et accusam et justo duo dolores et ea rebum.
            </li>
            <li>
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem.
            </li>
          </VlogOl>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogHeadline3>
            Technology
          </VlogHeadline3>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogFigure>
            <img src={headerimg} alt="Blabla" />
          </VlogFigure>
          <VlogParagraph>
              <strong>
                This is a strong image text
              </strong>
          </VlogParagraph>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogBottom/>
      
          <VlogDisclosures onClick={() => handleClick('disclosures')}>
            Disclosures
          </VlogDisclosures>
        </div>
      </VlogContent>

    </VlogContainer>
  )
}


export default Vlog;