import styled from "styled-components";

const BlogSection = styled.div`
  min-height: calc((var(--vh,1vh) * 100) - (75px * 2));
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 200px;
`

const BlogSectionText = styled.div`
  //font-family: Univers;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 40px;
  text-align: left;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgb(116, 81, 220);
`

const BlogSectionArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const BlogSectionArticleWrapper = styled.a`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: flex-end;
  text-decoration: none;
  cursor: pointer;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgb(77, 83, 101);
`

const BlogSectionArticleDate = styled.div`
  min-width: 80px;
  //font-family: Univers;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgb(77, 83, 101);
  @media (min-width: 576px) {
    min-width: 80px;
  }
  @media (min-width: 768px) {
    min-width: 200px;
  }
`

const BlogSectionArticleTitle = styled.div`
  flex: 1 1 0%;
  transition: color 250ms ease 0s;
  //font-family: "Circular Std";
  font-style: normal;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: -0.02em;
  color: #000000; // rgb(255, 255, 255);
  font-size: 25px;
  @media (min-width: 576px) {
    font-size: 30.57px;
  }
  :hover {
    color: rgb(81, 59, 147);
  }
`

const BlogSectionArticleArrowContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`

export { BlogSection, 
  BlogSectionText, 
  BlogSectionArticleContainer, 
  BlogSectionArticleWrapper,
  BlogSectionArticleDate,
  BlogSectionArticleTitle,
  BlogSectionArticleArrowContainer
 }; 