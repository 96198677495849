import React from "react";
import { useTheme, useThemeUpdate } from "./ThemeContext";
import { HeroSection, HeaderTextPlain, HeaderText, HeaderTextTwo, HeaderTextThree } from "../styles/HeroSection";
import { VlogFigure } from '../styles/VlogStyles'
import Baum from '../assets/baumlogosmall.png';


const Hero = () => {
  const liquidTheme = useTheme()
  const toggleTheme = useThemeUpdate()
  return (
    <HeroSection id="hero">
      <HeaderTextPlain>
        <div>
          Hetti<br/> Schubert-Schwall
        </div>
        <VlogFigure>
          <img src={Baum} alt="Blabla" />
        </VlogFigure>
      </HeaderTextPlain>
    </HeroSection>
  )
}

/*
THE <span>GLOBAL</span> TRANSITION <br/>
TO <span>DECENTRALIZED</span> <br/>
TECHNOLOGY REQUIRES <br/>
A NEW <span>FRAMEWORK</span>

The <span>global</span> transition to <br/>
<span>decentralized</span> internet <br/>
requires <span>bold entrepreneurs</span> 

The global transition to decentralized technology requires liquid protocols

The global transition to the next internet requires bold entrepreneurs

We back bold entrepreneurs building the tokenized internet

It's 1999 and we back bold entrepreneurs building the next internet



It's 1999 and the internet will be free, permissionless and tokenized.
The future of the internet is free, permissionless and tokenized.


*/

export default Hero;