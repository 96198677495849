import React from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { PortfolioSection,
  PortfolioText,
  PortfolioImages,
  PortfolioImageWrapper,
  PortfolioImageLabel
} from "../styles/PortfolioSection";


const PortfolioElement = ({link, image, text}) => {
  const navigate = useNavigate()
  const handleClick = (id) => {
    navigate(`/vlog/${id}`)
  }
  return (
    <div>
      <PortfolioImageWrapper  onClick={() => handleClick(link)}>
        <img alt="Portfolio" src={image}/>
        <PortfolioImageLabel>
          {text}
        </PortfolioImageLabel>
      </PortfolioImageWrapper>
    </div>
  )
}

export default PortfolioElement;