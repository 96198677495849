import styled from "styled-components";

const BodyContainer = styled.div`
  //text-align: center;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  background-color: #FFFFFF; //#282c34
  font-family: 'Open Sans', sans-serif;
`;

const BodyFlexContainer = styled.div`
//--vh
  min-height: calc(var(10.54,1vh) * 100);
  max-height: calc(var(10.54,1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export { BodyContainer, BodyFlexContainer };