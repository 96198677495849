import styled from "styled-components";

const VlogContainer = styled.div`
  margin-top: 200px;
  max-width: 700px;
  @media (max-width: 575px) {
    margin-top: 110px;
  }
`
const VlogTitle = styled.div`
  margin-bottom: 25px;
  //font-family: "Circular Std";
  font-style: normal;
  font-weight: 300;
  font-size: 30.57px;
  line-height: 116%;
  letter-spacing: -0.02em;
  color: #000000; //rgb(255, 255, 255)
`

const VlogDateAuthor = styled.div`
  margin-bottom: 50px;
  //font-family: Univers;
  font-size: 13px;
  line-height: 116%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgb(116, 81, 220);
`

const VlogContent = styled.div`
  //font-family: Circular;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.3;
  color: #000000; //rgb(255, 255, 255)
`

const VlogFigureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
  @media (min-width: 972px) {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }

  //@media (min-width: 1280px) {
  //  grid-template-columns: repeat(4, minmax(0px, 1fr));
  //}
`

const VlogFigure = styled.figure`
  //display: block;
  display: flex;
  justify-content: center;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  img {
    max-width: 100%;
  }
`

const VlogParagraph = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

const VlogParagraphLink = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-decoration: underline;
  cursor: pointer;
  a {
    color: #000000;
  }
`

const VlogHeadline3 = styled.h3`
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 400;
`

const VlogHeadline2 = styled.h2`
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 400;
`

const VlogOl = styled.ol`
  list-style-position: inside;
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
`

const VlogUl = styled.ul`
  list-style-position: inside;
  display: block;
  list-style-type: none;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
`

const VlogBottom = styled.div`
  border-top: 1px solid rgb(116, 81, 220);
  margin-top: 50px;
  padding-top: 50px;
  //padding-bottom: 20px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
`

const VlogDisclosures = styled.a`
  display: block;
  //font-family: Univers;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding-top: 50px;
  padding-bottom: 200px;
  color: #000000; //rgb(255, 255, 255)
  @media (max-width: 575px) {
    padding-bottom: 110px;
  }
`

export { VlogContainer, 
  VlogTitle, 
  VlogDateAuthor,
  VlogContent,
  VlogFigureGrid,
  VlogFigure,
  VlogParagraph,
  VlogParagraphLink,
  VlogHeadline3,
  VlogHeadline2,
  VlogOl,
  VlogUl,
  VlogBottom,
  VlogDisclosures
};