import React, { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { BodyContainer, BodyFlexContainer } from './styles/BodyContainer';
import { ContentContainer, ContentMobilePadding } from './styles/ContentContainer';
//import { HeroSection, HeaderText } from './styles/HeroSection';

import { ThemeProvider } from './components/ThemeContext';

import Nav from './components/Nav';
import Footer from './components/Footer';
import Home from './components/Home';
import Vlog from './components/Vlog';


//import './App.css';

function App() {
  return (
    <Router>
      <Fragment>
        <BodyContainer>
          <BodyFlexContainer>
            <Nav/>
            <ContentContainer>
              <ContentMobilePadding>
                <ThemeProvider>
                  <Routes>
                    <Route path='/' exact element={<Home/>} />
                    <Route path='/vlog/:id' exact element={<Vlog/>} />
                  </Routes>
                </ThemeProvider>
              </ContentMobilePadding>
            </ContentContainer>

            <Footer/>
          </BodyFlexContainer>
        </BodyContainer>
      </Fragment>
    </Router>
  );
}

export default App;

/*
<AppLogo>
  <img src={logo} alt="logo" />
</AppLogo>

<p>
  Edit <code>src/App.js</code> and save to reload.
</p>
<a
  className="App-link"
  href="https://reactjs.org"
  target="_blank"
  rel="noopener noreferrer"
>
  Learn React
</a>



*/
