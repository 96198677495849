import React from "react";
import logo from '../assets/HettiLogoCircle.svg';
import { ReachOutSection,
  ReachOutContainer,
  ReachOutTextContainer,
  ReachOutText,
  ReachOutButton
 } from "../styles/ReachOutSection";

const ReachOut = () => {
  return (
    <ReachOutSection id="contact">
      <ReachOutContainer>
        <img src={logo} />
          
        <ReachOutTextContainer>
          <ReachOutText>
            Kontaktiere die Künstlerin
          </ReachOutText>
          <a href="mailto:hetti@schubert-schwall.com">
            <ReachOutButton>
              Email
            </ReachOutButton>
          </a>
        </ReachOutTextContainer>
      </ReachOutContainer>
    </ReachOutSection>
  )
}

export default ReachOut;