import styled from "styled-components";

const HeroSection = styled.div`
  min-height: calc((var(--vh,1vh) * 100) - (75px * 2));
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  align-items: center;
  //text-align: center;
  overflow: hidden;
  margin-top: 65px;
  @media (min-width: 370px) {
    margin-top: 65px;
  }
`
const HeaderTextPlain = styled.div`
  font-weight: 400;
  position: absolute;
  max-width: 100%;
  font-size: 37px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000; //rgb(255, 255, 255)
  overflow: hidden;

  @media (min-width: 576px) {
    font-size: 50px;
  }
  @media (min-width: 768px) {
    font-size: 50px;
  }
  @media (min-width: 992px) {
    font-size: 60px;
  }
  @media (min-width: 1200px) {
    font-size: 60px;
  }
`

const HeaderText = styled.div`
  //font-family: "Circular Std";
  //font-style: bold;
  font-weight: 400;
  position: absolute;
  max-width: 100%;
  font-size: 37px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000; //rgb(255, 255, 255)
  overflow: hidden;


  
  //animation: 2.5s ease 0s infinite normal none running pulse;
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 2.5s;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
  animation-name: pulse;
  animation-play-state: running;
  animation-timing-function: ease;

  
  @media (min-width: 576px) {
    font-size: 50px;
  }
  @media (min-width: 768px) {
    font-size: 50px;
  }
  @media (min-width: 992px) {
    font-size: 60px;
  }
  @media (min-width: 1200px) {
    font-size: 60px;
  }
  span {
    color: #FFFFFF;
    text-shadow: white -1px 0px, white 0px 1px, white 1px 0px, white 0px -1px;
  }

  /*
  min-height: 60%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  //align-items: center;
  -webkit-box-pack: center;
  position: relative;
  justify-content: center;
  font-size: 70px;
  color: white;
  font-weight: bold;
  padding: 0px 200px 0px 200px;


  span {
    font: 800 70px Arial;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 50px;
    span {
      font: 800 50px Arial;
    }
  }
  @media screen and (max-width: 600px) {
    font-size: 40px;
    span {
      font: 800 40px Arial;
    }
  }
  */

  @keyframes pulse {
  0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(2px, 3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(5px, 2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
}
`

const HeaderTextTwo = styled.div`
  //font-family: "Circular Std";
  //font-style: bold;
  font-weight: 400;
  position: absolute;
  max-width: 100%;
  font-size: 37px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000; //rgb(255, 255, 255);
  overflow: hidden;

  //animation: 2.5s ease 0s infinite normal none running pulse;
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 2.5s;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
  animation-name: pulseTwo;
  animation-play-state: running;
  animation-timing-function: ease;

  @media (min-width: 576px) {
    font-size: 50px;
  }
  @media (min-width: 768px) {
    font-size: 50px;
  }
  @media (min-width: 992px) {
    font-size: 60px;
  }
  @media (min-width: 1200px) {
    font-size: 60px;
  }
  span {
    color: #FFFFFF;
    text-shadow: white -1px 0px, white 0px 1px, white 1px 0px, white 0px -1px;
  }

  @keyframes pulseTwo {
  0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(-5px, -2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
}
`

const HeaderTextThree = styled.div`
  //font-family: "Circular Std";
  //font-style: bold;
  font-weight: 400;
  position: absolute;
  max-width: 100%;
  font-size: 37px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000; //rgb(255, 255, 255);
  overflow: hidden;

  //animation: 2.5s ease 0s infinite normal none running pulse;
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 2.5s;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
  animation-name: pulseThree;
  animation-play-state: running;
  animation-timing-function: ease;

  @media (min-width: 576px) {
    font-size: 50px;
  }
  @media (min-width: 768px) {
    font-size: 50px;
  }
  @media (min-width: 992px) {
    font-size: 60px;
  }
  @media (min-width: 1200px) {
    font-size: 60px;
  }
  span {
    color: #FFFFFF;
    text-shadow: white -1px 0px, white 0px 1px, white 1px 0px, white 0px -1px;
  }

  @keyframes pulseThree {
  0% {
    transform: none;
    opacity: 1;
  }
  7% {
    transform: skew(-0.5deg, -0.9deg);
    opacity: 0.75;
  }
  10% {
    transform: none;
    opacity: 1;
  }
  27% {
    transform: none;
    opacity: 1;
  }
  30% {
    transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }
  35% {
    transform: none;
    opacity: 1;
  }
  52% {
    transform: none;
    opacity: 1;
  }
}
`


;

export { HeroSection, HeaderTextPlain, HeaderText, HeaderTextTwo, HeaderTextThree };