import styled from "styled-components";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  overflow-y: hidden;
  flex: 1 1 0%;

  //width: 100%;
  overflow-x: hidden;
  max-width: 1500px;
  
  //mobile padding
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 960px) {
    padding-left: 200px;
    padding-right: 200px;
  }



  //width: 80%;

  
  ::-webkit-scrollbar {
    display: none;
  } 
  

  /*
  background-color: #282c34;
  height: calc(100vh - 75px - 75px);
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  -webkit-box-align: center;
  overflow-y: auto;
  flex: 1 1 0%;
  //font-size: 60px;
  //font-size: calc(10px + 2vmin);
  color: white;
  //overflow: hidden;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  } 
  */
  /*
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  overflow-y: auto;
  flex: 1 1 0%;
  */
`;

const ContentMobilePadding = styled.div`
  width: 100%;
  overflow: hidden;
  max-width: 1500px;
  //padding-left: 40px;
  //padding-right: 40px;

  display: flex;
  -webkit-box-pack: center;
  justify-content: center;

  ::-webkit-scrollbar {
    display: none;
  } 

  
  @media (min-width: 960px) {
    padding-left: 200px;
    padding-right: 200px;
  }
  
`

export { ContentContainer, ContentMobilePadding };