import React, { useContext, useState } from "react";

const ThemeContext = React.createContext()
const ThemeUpdateContext = React.createContext()

// Custom Hooks
export function useTheme() {
  return useContext(ThemeContext)
}

export function useThemeUpdate() {
  return useContext(ThemeUpdateContext)
}


export function ThemeProvider({ children }) {
  const [liquidTheme, setLiquidTheme] = useState(true)
  
  function toggleTheme() {
    setLiquidTheme(prevLiquidTheme => !prevLiquidTheme)
  }

  return (
    <ThemeContext.Provider value={liquidTheme}>
      <ThemeUpdateContext.Provider value={toggleTheme}>
        {children}
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  )
}
