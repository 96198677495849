import React from "react";
//import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { NavContainerBottom, NavElement } from "../styles/NavContainer";

const Footer = () => {
  return (
    <NavContainerBottom>
        <HashLink smooth to='/#blog'>
          <NavElement>
            Exhibitions
          </NavElement>
        </HashLink>
        <HashLink smooth to='/#contact'>
          <NavElement>
            Contact
          </NavElement>
        </HashLink>
    </NavContainerBottom>
  )
};

export default Footer;