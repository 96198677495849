import React from "react";
//import { NavLink } from 'react-router-dom';
import logo from '../assets/GiniLogo.svg';
import { HashLink } from 'react-router-hash-link';
import { NavContainerTop, NavElementWrapper, NavElement } from "../styles/NavContainer";

const Nav = () => {
  return (
    <NavContainerTop>
        <HashLink smooth to='/vlog/vita'>
          <NavElement>
            About
          </NavElement>
        </HashLink>
        <HashLink smooth to='/#hero'>
          <NavElement>
            Home
          </NavElement>
        </HashLink>
        <HashLink smooth to='/#portfolio'>
          <NavElement>
            Projects
          </NavElement>
        </HashLink>
    </NavContainerTop>
  )
};

/*
 <NavLink to='/about' exact="true" activeclassname='active'>
  About
</NavLink>
<NavLink to='/logo' exact="true" activeclassname='active'>
  Logo
</NavLink>
<NavLink to='/portfolio' exact="true" activeclassname='active'>
  Portfolio
</NavLink>
*/

export default Nav;